function toggleRightSidebar() {
    const body = document.querySelector('body');
    const toggleBtnRSB = document.querySelector('.rsb-toggle-btn');
    const sidebarRSB = document.querySelector('.rsb-section');
    const overlay = document.querySelector('.overlay');

    if (!toggleBtnRSB || !sidebarRSB) return;

    toggleBtnRSB.addEventListener('click', () => {
        body.classList.toggle('show');
        sidebarRSB.classList.toggle('show');
        overlay?.classList.toggle('show');
    });

    const closeBtn = sidebarRSB.querySelector('.close-btn');
    if (closeBtn) {
        closeBtn.addEventListener('click', () => {
            sidebarRSB.classList.remove('show');
            overlay?.classList.remove('show');
            body.classList.remove('show');
        });
    }

    overlay?.addEventListener('click', () => {
        sidebarRSB.classList.remove('show');
        overlay.classList.remove('show');
        body.classList.remove('show');
    });

    document.addEventListener('keypress', (e) => {
        if (e.key === 'Escape') {
            sidebarRSB.classList.remove('show');
            overlay?.classList.remove('show');
            body.classList.remove('show');
        }
    });
}

toggleRightSidebar();
